import { useMainStore } from '../stores/mainStore';
import { useEffect, useState } from 'react';
import { base64EncodeKey } from '../utils';

export const Trans = ({ children }: { children: React.ReactNode }) => {
  const { translate, translations, role } = useMainStore();
  // const { translations } = useMainStore();
  const [translation, setTranslation] = useState(children);
  useEffect(() => {
    if (children && typeof children === 'string' && role && role >= 10) {
      translate(children);
    }
  }, [children]);
  useEffect(() => {
    if (children && typeof children === 'string') {
      setTranslation(translations[base64EncodeKey(children)] || children);
    }
  }, [translations, children]);
  if (translation) {
    return <>{translation}</>;
  }
  return null;
};
