import { useMainStore } from './stores/mainStore';

export const langCodeMap = {
  en: 'English',
  es: 'Español',
  fr: 'Français',
  de: 'Deutsch',
  it: 'Italiano',
  pt: 'Português',
  ru: 'Русский',
  zh: '中文',
  ja: '日本語',
  ko: '한국어',
  ar: 'العربية',
  tr: 'Türkçe',
  id: 'Bahasa Indonesia',
  hi: 'हिन्दी',
  vi: 'Tiếng Việt',
  th: 'ไทย',
};

export const langNameMap = {
  English: 'en',
  Spanish: 'es',
  French: 'fr',
  German: 'de',
  Italian: 'it',
  Portuguese: 'pt',
  Russian: 'ru',
  Chinese: 'zh',
  Japanese: 'ja',
  Korean: 'ko',
  Arabic: 'ar',
  Turkish: 'tr',
  Indonesian: 'id',
  Hindi: 'hi',
  Vietnamese: 'vi',
  Thai: 'th',
};

export function getCookie(name: string) {
  var dc = document.cookie;
  var prefix = name + '=';
  var begin = dc.indexOf('; ' + prefix);
  if (begin == -1) {
    begin = dc.indexOf(prefix);
    if (begin != 0) return null;
  } else {
    begin += 2;
  }
  var end = document.cookie.indexOf(';', begin);
  if (end == -1) {
    end = dc.length;
  }
  return decodeURI(dc.substring(begin + prefix.length, end));
}

export function deleteCookie(name: string) {
  document.cookie = `${name}=; Domain=lewdspace.com; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export const getTokenFromUrl = () => {
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');
  if (token) {
    localStorage.setItem('token', token);
    // remove token from url
    window.history.replaceState({}, document.title, window.location.pathname);
    useMainStore.getState().setUserValues(getTokenValues());
  }
};

export const base64EncodeKey = (original: string) => {
  const cleanedString = original.replace(/\s+/g, '').slice(0, 50);
  const encoded = new TextEncoder().encode(cleanedString);
  return btoa(String.fromCharCode(...encoded));
};

export const getImageSrc = (imagePath: string | undefined): string => {
  if (!imagePath) return '';
  if (imagePath.includes('[')) {
    const startIndex = imagePath.indexOf('[');
    const endIndex = imagePath.indexOf(']');
    const pathWithoutBrackets = imagePath.slice(startIndex + 1, endIndex);
    return `/public/${pathWithoutBrackets}`;
  }
  const pathWithoutBrackets = imagePath.replace(/[\[\]]/g, '');
  const pathWithoutExtras = pathWithoutBrackets.split('.jpg')[0] + '.jpg';
  return `/public/${pathWithoutExtras}`;
};

const showErrorModal = (
  message = 'An error occurred. Would you like to reload the page?',
  cause?: string
) => {
  useMainStore.getState().setError(message, cause);
};

export const fetchData = async (url: string) => {
  const language = localStorage.getItem('language') || 'en';
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
  };

  const { token } = getTokenValues();
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  try {
    const response = await fetch(
      `${url}${url.includes('?') ? '&' : '?'}language=${language}`,
      { headers }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (data.error) {
      showErrorModal(data.error, data.cause);
      setTimeout(() => {
        useMainStore.setState({ typing: false });
      }, 2000);
    } else {
      return data;
    }
  } catch (error: any) {
    console.error('Fetch error:', error);
    showErrorModal();
    throw error;
  }
};

export const postData = async (url: string, body: any) => {
  const language = localStorage.getItem('language') || 'en';
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
  };

  const { token } = getTokenValues();
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  try {
    const response = await fetch(`${url}?language=${language}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    if (data.error) {
      showErrorModal(data.error, data.cause);
      useMainStore.setState({ typing: false });
    } else {
      return data;
    }
  } catch (error: any) {
    console.error('Post error:', error);
    showErrorModal();
    throw error;
  }
};

function parseJwt(token: string) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

export function getTags(token?: string) {
  const localToken = localStorage.getItem('token');
  const tokenToUse = token || localToken;
  if (tokenToUse) {
    const tokenData = JSON.parse(atob(tokenToUse.split('.')[1]));
    const tags = tokenData?.tags;
    return tags;
  }
  return [];
}

export function getTokenValues() {
  const token = localStorage.getItem('token');
  if (token) {
    const tokenObject = parseJwt(token);
    if (!tokenObject) {
      localStorage.removeItem('token');
      return {
        token: undefined,
        tier: 0,
        user: undefined,
        language: 'English',
      };
    }
    const {
      expires,
      tier,
      user,
      tags,
      language,
      role,
      loggedIn,
    }: {
      expires: string;
      tier: number;
      user: string;
      tags: string[];
      language: string;
      role: number;
      loggedIn: string;
    } = parseJwt(token);
    if (tier) {
      localStorage.setItem('tier', tier.toString());
    }
    if (parseInt(expires) < Date.now()) {
      return {
        user,
        tier: 0,
        token: undefined,
        tags: [],
        language: language || 'English',
        role: role || 0,
        loggedIn: loggedIn || '',
      };
    } else {
      return {
        token,
        tier,
        user,
        tags: tags || [],
        language: language || 'English',
        role: role || 0,
        loggedIn: loggedIn || '',
      };
    }
  }
  return { token: undefined, tier: 0, user: undefined, language: 'English' };
}

const onlyUnique = (value: any, index: number, array: any[]) => {
  return array.indexOf(value) === index;
};

export const arrayUnique = (array: any[]) => {
  return array.filter(onlyUnique);
};

export const randomTimeout = (
  min: number,
  max: number,
  callback?: () => void
) => {
  return new Promise<void>((resolve) =>
    setTimeout(
      () => {
        callback && callback();
        resolve();
      },
      Math.floor(Math.random() * (max - min + 1)) + min
    )
  );
};
