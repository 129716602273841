import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { AppLayout } from './components/AppLayout';
import { Feed } from './components/Feed';
import { Profile } from './components/Profile';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Chat } from './components/Chat';
import { Inbox } from './components/Inbox';
import { Explore } from './components/Explore';
import { ErrorModal } from './components/ErrorModal';
import {
  Button,
  Typography,
  FormControlLabel,
  FormControl,
  Switch,
  Select,
  MenuItem,
} from '@mui/material';
import { Terms } from './components/Terms';
import { Privacy } from './components/Privacy';
import { useMainStore } from './stores/mainStore';
import { Game } from './components/Game';
import { GameLayout } from './components/GameLayout';
import { getTags, langCodeMap } from './utils';
import { SelectChangeEvent } from '@mui/material/Select';
import { Sitemap } from './components/Sitemap';
import { Signup } from './components/Signup';
import { SubscribeStarWelcome } from './components/SubscribeStarWelcome';
import { Tiers } from './components/Tiers';
import { PasswordReset } from './components/PasswordReset';
// Create a theme instance
const theme = createTheme({
  // You can customize the theme here
});

const AppContent: React.FC = () => {
  const location = useLocation();
  const pathname = location.pathname.split('/').slice(2).join('/');
  const [showTosModal, setShowTosModal] = useState(false);
  const [showVisibilityModal, setShowVisibilityModal] = useState(false);
  const tags = useMainStore((state) => state.tags);
  const setTag = useMainStore((state) => state.setTag);
  const initUser = useMainStore((state) => state.initUser);
  const [futasSelected, setFutasSelected] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  useEffect(() => {
    // Check TOS agreement on component mount
    const tosAgreed = localStorage.getItem('tosAgree') === 'true';
    if (!tosAgreed) {
      setShowTosModal(true);
    }
    initUser();
  }, []);

  useEffect(() => {
    const tags = getTags();
    let localTags = [];
    try {
      localTags = JSON.parse(localStorage.getItem('tags') || '[]');
    } catch (e) {
      console.error(e);
    }
    if (
      !showTosModal &&
      tags &&
      !tags.includes('language-set') &&
      !localTags.includes('language-set')
    ) {
      setShowVisibilityModal(true);
    } else {
      setShowVisibilityModal(false);
    }
    setTimeout(() => {
      if (
        tags &&
        !tags.includes('futas-set') &&
        localTags.includes('futas-set')
      ) {
        setShowVisibilityModal(true);
        localStorage.removeItem('language-set');
        setTag('futas', localTags.includes('futas'));
      }
    }, 300);
  }, [tags, showTosModal]);

  const handleTosAgree = () => {
    localStorage.setItem('tosAgree', 'true');
    setShowTosModal(false);
  };

  const handleVisibilityOk = () => {
    const futaUrl = window.location.pathname.includes('futa');
    setTag('futas', futaUrl || futasSelected, () => {
      setTag('language', true, () => {
        const curLang = localStorage.getItem('language');
        if (curLang && curLang !== selectedLanguage) {
          window.location.href = `${window.location.pathname.replace(
            curLang,
            selectedLanguage
          )}${window.location.href.includes('cosplay') ? '?restart=true' : ''}`;
        }
      });
    });
  };

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    setSelectedLanguage(event.target.value);
  };

  return (
    <>
      <Routes>
        <Route path="/game" element={<GameLayout />}>
          <Route path="cosplay/:charId" element={<Game gameType="cosplay" />} />
          <Route
            path="lost-and-found/:charId"
            element={<Game gameType="lost-and-found" />}
          />
          <Route
            path="helping-hand/:charId"
            element={<Game gameType="helping-hand" />}
          />
          <Route
            path="lost-and-found/:charId/futa"
            element={<Game gameType="lost-and-found" futa={true} />}
          />
          <Route
            path="minigame/:charId"
            element={<Game gameType="minigame" />}
          />
          <Route
            path="minigame/:charId/futa"
            element={<Game gameType="minigame" futa={true} />}
          />
        </Route>
        <Route path="/:lang/game" element={<GameLayout />}>
          <Route path="cosplay/:charId" element={<Game gameType="cosplay" />} />
          <Route
            path="helping-hand/:charId"
            element={<Game gameType="helping-hand" />}
          />
          <Route
            path="lost-and-found/:charId"
            element={<Game gameType="lost-and-found" />}
          />
          <Route
            path="lost-and-found/:charId/futa"
            element={<Game gameType="lost-and-found" futa={true} />}
          />
          <Route
            path="minigame/:charId"
            element={<Game gameType="minigame" />}
          />
          <Route
            path="minigame/:charId/futa"
            element={<Game gameType="minigame" futa={true} />}
          />
        </Route>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="signup" element={<Signup />} />
          <Route path="password-reset" element={<PasswordReset />} />
          <Route path="tiers" element={<Tiers />} />
          <Route
            path="subscribestar-welcome"
            element={<SubscribeStarWelcome />}
          />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="sitemap" element={<Sitemap />} />
        </Route>
        <Route path="/app" element={<AppLayout />}>
          <Route path="home" element={<Feed show="all" />} />
          <Route path="feed" element={<Feed show="all" />} />
          <Route path="explore" element={<Explore />} />
          <Route path="inbox" element={<Inbox />} />
          <Route path="profile/:id" element={<Profile />} />
          <Route path="chat/:charId" element={<Chat />} />
        </Route>
        <Route path="/:lang/app" element={<AppLayout />}>
          <Route path="home" element={<Feed show="all" />} />
          <Route path="feed" element={<Feed show="all" />} />
          <Route path="explore" element={<Explore />} />
          <Route path="inbox" element={<Inbox />} />
          <Route path="profile/:id" element={<Profile />} />
          <Route path="chat/:charId" element={<Chat />} />
        </Route>
        <Route path="/:lang" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="sitemap" element={<Sitemap />} />
          <Route path="signup" element={<Signup />} />
          <Route path="password-reset" element={<PasswordReset />} />
          <Route path="tiers" element={<Tiers />} />
          <Route
            path="subscribestar-welcome"
            element={<SubscribeStarWelcome />}
          />
        </Route>
        <Route path="*" element={<div>Page Not Found</div>} />
      </Routes>
      <ErrorModal />
      {showTosModal &&
        pathname !== 'terms' &&
        pathname !== 'privacy' &&
        pathname !== '' &&
        pathname !== 'sitemap' && (
          <div className="absolute top-0 left-0 flex justify-center items-center w-screen h-screen bg-white/80 backdrop-blur-md z-50 p-8 box-border text-center">
            <div className="max-w-[480px] container" id="container">
              <img
                src="/public/adult-sign-icon.svg"
                alt="18+"
                className="w-24 mx-auto mb-4"
              />
              <Button
                variant="outlined"
                onClick={handleTosAgree}
                sx={{
                  borderColor: 'black',
                  color: 'black',
                  mb: 2,
                  borderRadius: '25px',
                  fontSize: '1.2rem',
                  padding: '8px 30px',
                }}
              >
                Continue
              </Button>
              <Typography
                variant="body1"
                sx={{ fontSize: '1.2rem' }}
                className="text-gray-900"
              >
                This website contains age-restricted materials. Please leave the
                page immediately if you're a minor. By continuing you hereby
                agree to comply with all the{' '}
                <b>
                  <a href="/terms">TERMS AND CONDITIONS</a>
                </b>
                .
              </Typography>
            </div>
          </div>
        )}
      {pathname !== 'terms' &&
        pathname !== 'privacy' &&
        pathname !== 'sitemap' &&
        pathname !== '' &&
        showVisibilityModal && (
          <div className="absolute top-0 left-0 flex justify-center items-center w-screen h-screen bg-white/80 backdrop-blur-md z-50 p-8 box-border text-center">
            <div
              className="max-w-[480px] container flex flex-col items-center"
              id="container"
            >
              {!pathname.includes('game') ? (
                <>
                  <Typography variant="h5" component="h1" gutterBottom>
                    Visibility Settings
                  </Typography>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={futasSelected}
                          onChange={(e) => setFutasSelected(e.target.checked)}
                          name="futas"
                          color="primary"
                        />
                      }
                      label="Futas"
                    />
                  </FormControl>
                </>
              ) : null}
              <Typography
                sx={{ marginTop: 4 }}
                variant="h5"
                component="h1"
                gutterBottom
              >
                Language settings
              </Typography>
              <FormControl
                variant="outlined"
                sx={{ minWidth: 120, marginBottom: 2 }}
              >
                <Select
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Select Language' }}
                >
                  {Object.entries(langCodeMap).map(([code, name]) => (
                    <MenuItem key={code} value={code}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className="mt-4">
                <Button
                  variant="outlined"
                  onClick={handleVisibilityOk}
                  sx={{
                    borderColor: 'black',
                    color: 'black',
                    borderRadius: '25px',
                    fontSize: '1.2rem',
                    padding: '8px 20px',
                  }}
                >
                  OK
                </Button>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppContent />
      </Router>
    </ThemeProvider>
  );
};
