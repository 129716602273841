import { Outlet, useParams } from 'react-router-dom';
import { getTokenValues, langNameMap } from '../utils';
import { useMainStore } from '../stores/mainStore';
import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
export const GameLayout = () => {
  const params = useParams();
  const { language } = getTokenValues();
  if (!params.lang && language) {
    window.location.href = `/${langNameMap[language as keyof typeof langNameMap]}${window.location.pathname}`;
  }
  localStorage.setItem(
    'language',
    params.lang || langNameMap[language as keyof typeof langNameMap]
  );
  const { getTranslations, translations } = useMainStore();
  useEffect(() => {
    getTranslations();
  }, []);
  if (Object.keys(translations || {}).length === 0) {
    return (
      <div className="flex items-center justify-center h-screen">
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className="my-auto" style={{ height: 'min-content' }}>
      <div
        style={{ height: '100dvh' }}
        className="overflow-hidden flex items-center justify-center from-yellow-50 via-teal-300 to-yellow-50 bg-gradient-to-br"
      >
        <div style={{ height: '100dvh' }} className="flex flex-col">
          <div
            style={{ height: '100dvh', paddingTop: '10px' }}
            className="w-screen md:w-[450px] px-7 py-7 mx-auto bg-white rounded-2xl shadow-xl"
          >
            <div className="max-w-md mx-auto space-y-6">
              <div className="flex flex-col relative">
                {/* <CosplayHeader /> */}
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
