import { Outlet, useParams } from 'react-router-dom';
import { Header } from './Header';
import { useMainStore } from '../stores/mainStore';
import { useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { getTokenValues, langNameMap } from '../utils';

export const Layout = () => {
  const params = useParams();
  const { language } = getTokenValues();
  const dontRedirect = ['/terms', '/privacy'].find((path) =>
    window.location.pathname.includes(path)
  );
  if (!params.lang && language && !dontRedirect) {
    window.location.href = `/${langNameMap[language as keyof typeof langNameMap]}${window.location.pathname}${window.location.search}`;
  }
  localStorage.setItem(
    'language',
    params.lang || langNameMap[language as keyof typeof langNameMap]
  );
  const { getTranslations, translations } = useMainStore();
  useEffect(() => {
    getTranslations();
  }, []);
  if (Object.keys(translations || {}).length === 0) {
    return (
      <div className="flex items-center justify-center h-screen">
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <div className="bg-[#fbf7e9] min-h-screen">
        {[
          'privacy',
          'terms',
          'sitemap',
          '/game/',
          'signup',
          'password-reset',
          'tiers',
          'subscribestar-welcome',
        ].find((path) => window.location.pathname.includes(path)) ? null : (
          <div
            className="
            bg-[url('/public/mainImage2.gif')] 
            bg-cover 
            bg-top 
            bg-no-repeat 
            w-full 
            h-[90vh]  /* Default for mobile devices */
            md:h-[110vh] /* Applies to medium and larger screens */
            m-0 
            p-0 
            absolute 
            top-0 
            left-0
          "
          ></div>
        )}
        <div className="mx-auto max-w-[600px] px-4">
          <Header />
          <Outlet />
        </div>
      </div>
    </>
  );
};
