import { useLocation } from 'react-router-dom';
import { CloseButton } from './common/CloseButton';

export const Header = () => {
  const location = useLocation();
  const showCloseButton = [
    'privacy',
    'terms',
    'sitemap',
    'signup',
    'tiers',
  ].find((path) => location.pathname.includes(path));

  return (
    <div className="flex pb-5 relative">
      <div className="flex-1">
        <a href="/">
          {/* <img
            src="/public/logo-white.png"
            style={{ height: '48px', width: 'auto' }}
            className="m-4"
            alt="Logo"
          /> */}
        </a>
      </div>
      {showCloseButton && <CloseButton />}
    </div>
  );
};
