import { Button, Typography } from '@mui/material';
import { Trans } from './Trans';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import { getTokenFromUrl } from '../utils';
export const SubscribeStarWelcome = () => {
  getTokenFromUrl();
  return (
    <div className="container mx-auto px-4 py-8">
      <Typography variant="h4" sx={{ mb: 2, textAlign: 'center' }}>
        <Trans>Welcome to LewdSpace!</Trans>
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        <Trans>
          We're excited to have you join our steamy, thrilling dating simulator
          experience. By subscribing through SubscribeStar, you've unlocked some
          exclusive benefits that are only available to our subscribers.
        </Trans>
      </Typography>
      <div className="flex justify-center">
        <Button
          variant="contained"
          style={{
            backgroundColor: 'rgb(250, 92, 92)',
            color: 'white',
            fontSize: '1.5rem',
            padding: '10px 20px',
            marginBottom: '20px',
            borderRadius: '20px',
            minWidth: '290px',
          }}
          startIcon={
            <SportsEsportsIcon style={{ height: '2em', width: 'auto' }} />
          }
          href="/app/home"
        >
          <Trans>Run the game</Trans>
        </Button>
      </div>
      <Typography variant="body1" sx={{ mb: 4 }}>
        <Trans>To manage your subscription navigate to the profile page.</Trans>
      </Typography>
      <img
        src="/public/profileView.jpg"
        className="mx-2 my-4"
        alt="Profile View"
      />
    </div>
  );
};
