import { Typography, TextField, Button } from '@mui/material';
import { Trans } from './Trans';
import { useState } from 'react';
import { useMainStore } from '../stores/mainStore';
import { useSearchParams, useNavigate } from 'react-router-dom';

export const PasswordReset = () => {
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [errors, setErrors] = useState({
    password: '',
    passwordRepeat: '',
  });

  const resetPassword = useMainStore((state) => state.resetPassword);
  const navigate = useNavigate();

  const validatePassword = (password: string) => {
    const hasMinLength = password.length >= 8;
    const hasLowerCase = /[a-z]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);

    return hasMinLength && hasLowerCase && hasUpperCase && hasNumber;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Reset errors
    setErrors({
      password: '',
      passwordRepeat: '',
    });

    // Validate inputs
    let hasErrors = false;

    if (!validatePassword(password)) {
      setErrors((prev) => ({
        ...prev,
        password:
          'Password must be at least 8 characters long and contain uppercase, lowercase, and numbers',
      }));
      hasErrors = true;
    }

    if (password !== passwordRepeat) {
      setErrors((prev) => ({
        ...prev,
        passwordRepeat: 'Passwords do not match',
      }));
      hasErrors = true;
    }

    if (hasErrors) return;

    const secret = searchParams.get('secret');
    const userId = searchParams.get('userId');

    if (!secret || !userId) {
      setErrors((prev) => ({
        ...prev,
        password: 'Invalid reset link',
      }));
      return;
    }

    try {
      setLoading(true);
      await resetPassword(userId, secret, password, passwordRepeat, navigate);
    } finally {
      setLoading(false);
      setPassword('');
      setPasswordRepeat('');
    }
  };

  return (
    <div className="container mx-auto px-4 py-6">
      <Typography variant="h4" sx={{ mb: 2, textAlign: 'center' }}>
        <Trans>Reset Password</Trans>
      </Typography>
      <Typography variant="body1" sx={{ mb: 3, textAlign: 'center' }}>
        <Trans>Please enter your new password.</Trans>
      </Typography>
      <form onSubmit={handleSubmit} className="max-w-sm mx-auto">
        <TextField
          fullWidth
          type="password"
          label={<Trans>New Password</Trans>}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!errors.password}
          helperText={errors.password}
          sx={{ mb: 2 }}
          size="small"
        />
        <TextField
          fullWidth
          type="password"
          label={<Trans>Repeat Password</Trans>}
          value={passwordRepeat}
          onChange={(e) => setPasswordRepeat(e.target.value)}
          error={!!errors.passwordRepeat}
          helperText={errors.passwordRepeat}
          sx={{ mb: 3 }}
          size="small"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          sx={{ height: '40px' }}
        >
          {loading ? (
            <Trans>Resetting...</Trans>
          ) : (
            <Trans>Reset Password</Trans>
          )}
        </Button>
      </form>
    </div>
  );
};
