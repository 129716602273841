import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMainStore } from '../stores/mainStore';
import { getImageSrc } from '../utils';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  TextField,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Badge,
} from '@mui/material';
import styled from '@emotion/styled';
import { styled as muiStyled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { Trans } from './Trans';

const StyledCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: transform 0.2s;
  position: relative;
  &:hover {
    transform: translateY(-4px);
  }
`;

const StyledCardMedia = styled(CardMedia)`
  height: 200px;
`;

const StyledDescription = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledTitle = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledBadge = muiStyled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'transparent',
    background: 'linear-gradient(to top, #fde047, #f97316)',
    color: 'white',
    fontSize: '1rem',
    height: '60px',
    minWidth: '60px',
    padding: '0',
    transform: 'translate(50%, 50%)',
    clipPath:
      'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const Explore: React.FC = () => {
  const navigate = useNavigate();
  const { lang } = useParams();
  const { characters, getCharacters } = useMainStore();
  const [searchTerm, setSearchTerm] = useState('');
  const [levelFilter, setLevelFilter] = useState('all');

  useEffect(() => {
    getCharacters();
  }, []);

  const filteredCharacters = characters
    .filter((char) =>
      char.name?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((char) => {
      if (levelFilter === 'all') return true;
      const score = char.score || 0;
      const level = Math.floor(score / 30) + 1;
      switch (levelFilter) {
        case '1-5':
          return level >= 1 && level <= 5;
        case '5-10':
          return level > 5 && level <= 10;
        case '10-20':
          return level > 10 && level <= 20;
        case '20-30':
          return level > 20 && level <= 30;
        default:
          return true;
      }
    });

  return (
    <div className="flex flex-col app-container1 p-4 overflow-auto">
      <Box sx={{ mb: 4 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Search by name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Level Filter</InputLabel>
              <Select
                value={levelFilter}
                label="Level Filter"
                onChange={(e) => setLevelFilter(e.target.value)}
              >
                <MenuItem value="all">
                  <Trans>All</Trans>
                </MenuItem>
                <MenuItem value="1-5">1-5</MenuItem>
                <MenuItem value="5-10">5-10</MenuItem>
                <MenuItem value="10-20">10-20</MenuItem>
                <MenuItem value="20-30">20-30</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={3}>
        {filteredCharacters.map((character) => (
          <Grid item xs={6} sm={6} md={6} key={character._id}>
            <StyledCard
              onClick={() =>
                navigate(
                  `${lang ? `/${lang}` : ''}/app/profile/${character._id}`
                )
              }
            >
              <StyledBadge
                badgeContent={Math.floor((character.score || 0) / 30) + 1}
                overlap="circular"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{ position: 'absolute', top: 130, right: 24 }}
              >
                <div style={{ width: 30, height: 30 }} />
              </StyledBadge>
              <StyledCardMedia
                image={getImageSrc((character.images || '').split('|')[0])}
                title={character.name}
              />
              <CardContent>
                <StyledTitle variant="h6">{character.name}</StyledTitle>
                <StyledDescription variant="body2" color="text.secondary">
                  {character.descriptionTrans || character.description}
                </StyledDescription>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
