import { Typography } from '@mui/material';
import { Trans } from './Trans';

export const Tiers = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <Typography variant="h4" sx={{ mb: 2, textAlign: 'center' }}>
        <Trans>Tiers</Trans>
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        <Trans>
          You can update your tier at any time. Just navigate to the
          subscription platform you used to subscribe, and select a new tier.
        </Trans>
        <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
          <li>
            <a
              target="_blank"
              href="https://www.patreon.com/lewdspace/membership"
              className="underline"
            >
              Patreon
            </a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://subscribestar.adult/lewdspace"
              className="underline"
            >
              SubscribeStar
            </a>
          </li>
        </ul>
        <img src="/public/tiers3.png" alt="Tiers" className="mx-2 my-4" />
        <Trans>
          After updating your tier, you can logout and login again, or just
          click the "synchronize" button in the profile view
        </Trans>
        <img
          src="/public/synchronize.jpg"
          className="mx-2 my-4"
          alt="synchronize"
        />
      </Typography>
    </div>
  );
};
