// src/components/Sitemap.tsx
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMainStore } from '../stores/mainStore';
import { Trans } from './Trans';

export const Sitemap: React.FC = () => {
  const { characters, getCharacters } = useMainStore();
  const params = useParams();
  useEffect(() => {
    getCharacters();
  }, []);
  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">
        <Trans>Sitemap</Trans>
      </h1>
      <ul className="list-disc pl-5 space-y-2">
        <li>
          <Link
            to={`/${params.lang || 'en'}`}
            className="text-blue-500 hover:underline"
          >
            <Trans>Home</Trans>
          </Link>
        </li>
        <li>
          <Link to="/terms" className="text-blue-500 hover:underline">
            <Trans>Terms and Conditions</Trans>
          </Link>
        </li>
        <li>
          <Link to="/privacy" className="text-blue-500 hover:underline">
            <Trans>Privacy Policy</Trans>
          </Link>
        </li>
        <li>
          <Link to="/sitemap" className="text-blue-500 hover:underline">
            <Trans>Sitemap</Trans>
          </Link>
        </li>
        <li>
          <Link
            to={`/${params.lang || 'en'}/app/home`}
            className="text-blue-500 hover:underline"
          >
            <Trans>App Home</Trans>
          </Link>
        </li>
        <li>
          <Link
            to={`/${params.lang || 'en'}/app/feed`}
            className="text-blue-500 hover:underline"
          >
            <Trans>Feed</Trans>
          </Link>
        </li>
        <li>
          <Link
            to={`/${params.lang || 'en'}/app/explore`}
            className="text-blue-500 hover:underline"
          >
            <Trans>Explore</Trans>
          </Link>
        </li>
        <li>
          <Link
            to={`/${params.lang || 'en'}/app/inbox`}
            className="text-blue-500 hover:underline"
          >
            <Trans>Inbox</Trans>
          </Link>
        </li>
        <li>
          <Link
            to={`/${params.lang || 'en'}/app/profile/me`}
            className="text-blue-500 hover:underline"
          >
            <Trans>User Profile</Trans>
          </Link>
        </li>

        {characters.map((char) => (
          <li key={char._id.toString() + 'chat'}>
            <Link
              to={`/${params.lang || 'en'}/app/chat/${char._id.toString()}`}
              className="text-blue-500 hover:underline"
            >
              <Trans>Chat with </Trans>
              {char.name}
            </Link>
          </li>
        ))}
        {characters.map((char) => (
          <li key={char._id.toString() + 'profile'}>
            <Link
              to={`/${params.lang || 'en'}/app/profile/${char._id.toString()}`}
              className="text-blue-500 hover:underline"
            >
              {char.name}
              <Trans> profile</Trans>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sitemap;
