import { Typography, TextField, Button } from '@mui/material';
import { Trans } from './Trans';
import { useState } from 'react';
import { useMainStore } from '../stores/mainStore';

export const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    passwordRepeat: '',
  });

  const emailSignup = useMainStore((state) => state.emailSignup);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password: string) => {
    const hasMinLength = password.length >= 8;
    const hasLowerCase = /[a-z]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);

    return hasMinLength && hasLowerCase && hasUpperCase && hasNumber;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Reset errors
    setErrors({
      email: '',
      password: '',
      passwordRepeat: '',
    });

    // Validate inputs
    let hasErrors = false;

    if (!validateEmail(email)) {
      setErrors((prev) => ({
        ...prev,
        email: 'Please enter a valid email address',
      }));
      hasErrors = true;
    }

    if (!validatePassword(password)) {
      setErrors((prev) => ({
        ...prev,
        password:
          'Password must be at least 8 characters long and contain uppercase, lowercase, and numbers',
      }));
      hasErrors = true;
    }

    if (password !== passwordRepeat) {
      setErrors((prev) => ({
        ...prev,
        passwordRepeat: 'Passwords do not match',
      }));
      hasErrors = true;
    }

    if (hasErrors) return;

    try {
      setLoading(true);
      await emailSignup(email, password, passwordRepeat);
    } finally {
      setLoading(false);
      setEmail('');
      setPassword('');
      setPasswordRepeat('');
    }
  };

  return (
    <div className="container mx-auto px-4 py-6">
      <Typography variant="h4" sx={{ mb: 2, textAlign: 'center' }}>
        <Trans>Sign Up</Trans>
      </Typography>
      <Typography variant="body1" sx={{ mb: 3, textAlign: 'center' }}>
        <Trans>Sign up for a free account with your email address.</Trans>
      </Typography>
      <form onSubmit={handleSubmit} className="max-w-sm mx-auto">
        <TextField
          fullWidth
          type="email"
          label={<Trans>Email</Trans>}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!errors.email}
          helperText={<Trans>{errors.email}</Trans>}
          sx={{ mb: 2 }}
          size="small"
        />
        <TextField
          fullWidth
          type="password"
          label={<Trans>Password</Trans>}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!errors.password}
          helperText={<Trans>{errors.password}</Trans>}
          sx={{ mb: 2 }}
          size="small"
        />
        <TextField
          fullWidth
          type="password"
          label={<Trans>Repeat Password</Trans>}
          value={passwordRepeat}
          onChange={(e) => setPasswordRepeat(e.target.value)}
          error={!!errors.passwordRepeat}
          helperText={<Trans>{errors.passwordRepeat}</Trans>}
          sx={{ mb: 3 }}
          size="small"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
          sx={{ height: '40px' }}
        >
          {loading ? <Trans>Signing up...</Trans> : <Trans>Sign Up</Trans>}
        </Button>
      </form>
      <div className="max-w-md mx-auto mt-8">
        <Typography variant="h6" sx={{ mb: 3, textAlign: 'center' }}>
          <Trans>Alternative Sign Up Options</Trans>
        </Typography>

        <Typography variant="body1" sx={{ mb: 3, textAlign: 'center' }}>
          <Trans>
            You can sign up for a free or paid account using one of the
            following platforms:
          </Trans>
        </Typography>

        <div className="flex justify-center gap-4 mb-4">
          <a
            target="_blank"
            href="https://www.patreon.com/lewdspace/membership"
            className="flex items-center gap-3 px-6 py-2 rounded-lg border-2 border-[#FF424D] text-[#FF424D] hover:bg-[#FF424D] hover:text-white transition-all group"
          >
            <div className="w-8 h-8 rounded-full flex items-center justify-center">
              <img
                src="/public/patreonLogo.png"
                alt="Patreon"
                className="w-6 h-6 object-contain"
              />
            </div>
            <span className="font-medium">Patreon</span>
          </a>
          <a
            target="_blank"
            href="https://subscribestar.adult/lewdspace"
            className="flex items-center gap-3 px-6 py-2 rounded-lg border-2 border-[#0088CC] text-[#0088CC] hover:bg-[#0088CC] hover:text-white transition-all group"
          >
            <div className="w-8 h-8 rounded-full flex items-center justify-center">
              <img
                src="/public/subscribeStarSmall.png"
                alt="SubscribeStar"
                className="w-6 h-6 object-contain"
              />
            </div>
            <span className="font-medium">SubscribeStar</span>
          </a>
        </div>

        <Typography variant="body1" sx={{ mb: 2, mt: 8, textAlign: 'center' }}>
          <Trans>Navigate to the platform and select your tier:</Trans>
        </Typography>

        <img
          src="/public/tiers3.png"
          alt="Tiers"
          className="w-full rounded-lg shadow-md mb-6"
        />

        <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
          <Trans>
            After subscribing, you will be able to login and access additional
            benefits that your tier provides. Just click the login button in the
            top right corner of the app and select the platform you used to
            subscribe.
          </Trans>
        </Typography>

        <img
          src="/public/loginInstructions.jpg"
          className="w-full rounded-lg shadow-md mb-6"
          alt="Login"
        />

        <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
          <Trans>
            You can then navigate to Profile page and view your active tier.
          </Trans>
        </Typography>

        <img
          src="/public/profileView.jpg"
          className="w-full rounded-lg shadow-md mb-6"
          alt="Profile View"
        />
      </div>
    </div>
  );
};
