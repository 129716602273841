import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { getTokenValues, langNameMap } from '../utils';

export const LangLink = ({
  children,
  to,
  className,
}: {
  children: React.ReactNode;
  to: string;
  className?: string;
}) => {
  const { lang } = useParams();
  const { language } = getTokenValues();
  const languageParam =
    lang || language ? langNameMap[language as keyof typeof langNameMap] : '';
  return (
    <Link
      to={`${languageParam ? `/${languageParam}` : ''}${to}`}
      className={className}
    >
      {children}
    </Link>
  );
};
